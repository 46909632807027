import React from 'react';
import Img from 'gatsby-image';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import styles from './our-story-timeline-card.module.scss';

const OurStoryTimelineCard = ({data, index}) => {
    const containerStyle = index %2 === 0 ? styles.ourStoryTimelineCardContainerLight : styles.ourStoryTimelineCardContainerDark;
    return (
        <div className={containerStyle}>
            <div className={styles.ourStoryTimelineCardContent}>
                <div className={styles.ourStoryTimelineCardTextContainer}>
                    <div className={styles.ourStoryTimelineCardTitle}>{data.title}</div>
                    <div className={styles.ourStoryTimelineCardDescription} dangerouslySetInnerHTML={{ __html:  data.description }}></div>
                </div>
                <Img className={styles.ourStoryTimelineCardImage}
                        fluid={data.image.fluid} />
            </div>
        </div>
    );
}

export default OurStoryTimelineCard;
