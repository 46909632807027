// Libs.
import React from 'react';
// Components.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import OurStoryComponent from '../components/our-story/our-story-container';

const OurStoryPage = () => {
  return (
    <Layout>
      <SEO title="Our Story" />
      <OurStoryComponent />
    </Layout>
  );
};

export default OurStoryPage;
