import React from 'react';

import styles from './our-story-container.module.scss';
import { ourStory } from '../../locale/locale.json';

import OurStoryThreeBrother from './our-story-three-brothers';
import OurStoryTimelineCard from './our-story-timeline-card';
import {useOurStoryPageQuery} from "../../hooks/useOurStoryPageQuery";

const OurStoryComponent = () => {
  const {configPagesOurStory: ourStoryData} = useOurStoryPageQuery()
  return (
    <div className={styles.ourStoryContainerMain}>
      <div className={styles.ourStoryContainerBody}>
        <OurStoryThreeBrother/>
      </div>
      {ourStoryData.ctaSection.map((entry, index) => <OurStoryTimelineCard index={index} data={entry} key={index}/>)}
    </div>
  );
};
export default OurStoryComponent;
