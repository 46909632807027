import { useStaticQuery, graphql } from 'gatsby';
import {drupalTransformer} from '../libs/transform/index'
import {map} from 'lodash'

export const useOurStoryPageQuery = () => {
  return drupalTransformer.transformNode(useOurStory());
};

const useOurStory = () => {
  return useStaticQuery(graphql`
    query ourStory {
      configPagesOurStory {
        field_cta_title
        relationships {
          field_cta_section {
            title: field_type_label
            description: field_answer {
              value
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fluid {
                      srcSet
                      src
                      base64
                      aspectRatio
                      sizes
                    }
                  }
                }
              }
            }
          }
          field_biography_sections {
            parent_field_name
            title:field_type_label
            subtitle:field_sublable
            description: field_answer {
              value
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fluid {
                      srcSet
                      src
                      base64
                      aspectRatio
                      sizes
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  `);
};
