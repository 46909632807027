import React from 'react';
import styles from './our-story-three-brothers.module.scss'
import LoftCard from '../common/loft-card'
import {useOurStoryPageQuery} from "../../hooks/useOurStoryPageQuery";

const OurStoryThreeBrothers = () => {
  const {configPagesOurStory: ourStoryData} = useOurStoryPageQuery()
  return (
    <>
      <div className={styles.ourStoryThreeBrothersTitle}>{ourStoryData.ctaTitle}</div>
      <div className={styles.ourStoryThreeBrothersCardsContainer}>
      { ourStoryData.biographySections
        .map((card, index) => {
          return <LoftCard
            card={card}
            key={index} />
        })
      }
      </div>
    </>
  );
}

export default OurStoryThreeBrothers;
